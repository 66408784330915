import React from "react"
import Layout from "../components/layout"
import Card from "../components/card";

export default ({}) => {
  // You have to go to getform.io -> register and get the form id.
  let GETFORM_IO_URL = 'bed54f37-7cb9-4a9a-8120-109918d65ae8';
  return (
    <Layout>
      <section className="consultancy">
        <header className="main">
          <h1>Open for Consultancy</h1>
        </header>
        <div className="cards-wrapper">
          <Card title="Drupal 6/7/8/9" icon="drupal" description="<p>With over a decade of experience working with Drupal, I have a deep understanding of its architecture and capabilities. I've successfully migrated systems from Drupal 6/7 to Drupal 8/9, built lots of custom integrations and custom migrations. My expertise spans across back-end, front-end, and DevOps.</p>" />
          <Card title="Scraping (Data Mining)" icon="link" description="<p>I have a strong background in data mining, using tools like <strong>Scrapy</strong> to extract valuable information. My experience in this field allows me to efficiently gather, analyze, and interpret large datasets, providing valuable insights that can drive decision-making and strategy.</p>" />
          <Card title="Mentorship / Teaching" icon="mortar-board" description="<p>As a tech lead and co-founder of multiple organizations, I've had the opportunity to mentor and teach many individuals. I believe in empowering others with knowledge and skills, and I have a knack for explaining complex concepts in an understandable way.</p>" />
          <Card title="AWS (Cloud Architecture)" icon="cloud" description="<p>I've had the opportunity to work with AWS Cloud, which has helped me learn about cloud architecture. I've gained experience with tools like <strong>AWS</strong>, <strong>CDK</strong>, and <strong>Fargate</strong>, which have been valuable in managing cloud solutions.</p>" />
          <Card title="Startup & Tech Leadership" icon="rocket" description="<p>As a co-founder and tech lead, I've learned a lot about the challenges startups face and the importance of good leadership. I've tried to guide my teams as best I can and have learned a lot from these experiences.</p>" />
          <Card title="AI Integrations" icon="code" description="<p>I've developed an interest in AI and have had the chance to work on a few AI-powered solutions. This has given me some insight into AI technologies and how they can be integrated into business operations.</p>" />
          <Card title="Community & Events" icon="users" description="<p>I am passionate about building IT open-source communities and organizing both online and offline events. I have experience in organizing a variety of events such as DrupalCamps, Hackathons, and CodeSprints. My goal is to foster a collaborative environment where ideas can be shared and innovation can thrive.</p>" />
          <div className="card-cta">
            <h3>Ready to take the next step?</h3>
            <p>Every project is unique. Let's discuss how my expertise can help you achieve your goals.</p>
            <a href="https://calendly.com/d/yqr-p9z-tch/15min">Book a <strong>FREE</strong> Consultancy Session</a>
          </div>
        </div>
      </section>
    </Layout>
  )
}
