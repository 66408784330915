import React from 'react';
import PropTypes from 'prop-types';

const Card = ({ title, icon, description }) => (
    <div className="card-container">
      <div className="card-icon"><i className={`fa fa-${icon}`}></i></div>
      <h2 className="card-title">{title}</h2>
      <div className="card-description" dangerouslySetInnerHTML={{ __html: description }} />
    </div>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Card;
